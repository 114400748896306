import $ from 'jquery'

$(document).ready( initHeroFuncs )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=hero', initHeroFuncs)
}

function playPause() {
  let el = $(this);
  let vid = el.prev().find('video');
  if ( el.hasClass('paused') ) {
    vid.get(0).play();
  } else {
    vid.get(0).pause();
  }
  el.toggleClass('paused');
}

function initHeroFuncs(){
  $(document).on('click', '#playpause', playPause)
}
